<template>
    <RouterTabs :paths="paths"></RouterTabs>
</template>

<script>
  import RouterTabs from "@/components/Common/RouterTabs";

  export default {
    name: "UsersView",
    components: {
      RouterTabs
    },
    computed: {
      paths () {
        return [
          {
            name: "admin:users:webmasters",
            label: this.$t("admin.users.tabs.webmasters"),
            permissions: [
              "WEBMASTERS.LIST.ALL",
              "WEBMASTERS.LIST.OWN",
              "WEBMASTERS.LIST.UNBIND"
            ]
          },
          {
            name: "admin:users:advertisers",
            label: this.$t("admin.users.tabs.advertisers"),
            permissions: [
              "ADVERTISERS.LIST.ALL",
              "ADVERTISERS.LIST.OWN",
              "ADVERTISERS.LIST.UNBIND"
            ]
          },
          {
            name: "admin:users:administrators",
            label: this.$t("admin.users.tabs.administrators"),
            permissions: "SUPER.PERMISSION"
          }
        ];
      }
    }
  };
</script>
