<template>
    <div class="router-tabs">
        <el-tabs
            v-if="pathsNotEmpty"
            v-model="activeTab">
            <template v-for="path in visiblePath">
                <el-tab-pane
                    :key="path.name + path.label"
                    :label="path.label">
                </el-tab-pane>
            </template>
        </el-tabs>
        <!--        <transition :name="animation">-->
        <router-view></router-view>
        <!--        </transition>-->
    </div>
</template>

<script>
  import { hasPermissions } from "@core/mixins/permissions";
    
  export default {
    name: "RouterTabs",
    props: {
      paths: {
        type: Array,
        default: () => []
      }
    },
    created () {
      this.setActiveTabByRouteName();
    },
    data () {
      return {
        activeTab: null
        // animation: null
      };
    },
    computed: {
      pathsNotEmpty () {
        return this.permittedPaths.length > 0;
      },
            
      permittedPaths () {
        const userPermissions = (this.$store || { getters: {} }).getters.permissions;
        return this.paths.filter(path => hasPermissions(path.permissions, userPermissions));
      },

      visiblePath () {
        return this.permittedPaths.filter(path => !path.hasOwnProperty("visible") || path.visible);
      },
            
      updateConditions () {
        return JSON.stringify({
          ...this.permittedPaths,
          path: this.$route.path
        });
      }
    },
    methods: {
      setActiveTabByRouteName () {
        const index = this.permittedPaths.findIndex(el => el.name === this.$route.name);
        this.activeTab = index === -1 ? "0" : index.toString();
      }
    },
    watch: {
      $route () {
        this.setActiveTabByRouteName();
      },
            
      activeTab (value, old) {
        if (this.pathsNotEmpty && this.$route.name !== this.permittedPaths[value].name) {
          // TODO: need add new animation
          // this.animation = value > old ? "slide-prev" : "slide-next";
          this.$router.push({ name: this.permittedPaths[value].name }).catch(_ => {});
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    .router-tabs {
        width: 100%;
        
        ::v-deep {
            .el-tabs__item {
                color: #9AA3B4;
                
                &.is-active {
                    color: #162239;
                }
            }
    
            .el-tabs__active-bar {
                background-color: #FE2615;
            }
    
            .el-tabs__nav-wrap {
                padding: 24px;
                &:after {
                    height: 1px;
                }
            }
        }
    }
</style>
